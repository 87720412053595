import React from "react"
import { graphql } from "gatsby"

import ContentModules from "../components/Page/ContentModules"
import Layout from "../components/Layout"
import SEO from "../components/seo"

const Page = ({ data }) => {
  const { page } = data
  return (
    <Layout locale={page.node_locale}>
      <SEO
        lang={page.node_locale.substring(0, 2)}
        title={page.pageTitle}
        description={page.metaDescription.metaDescription}
      />
      <ContentModules allModules={page.contentModules} />
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query PageQuery($slug: String) {
    page: contentfulPage(slug: { eq: $slug }) {
      ...PageContent
    }
  }
`
